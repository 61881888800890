@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































::v-deep {
  .el-page-header__title {
    word-break: keep-all !important;
  }
}
