@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































.table-fixed {
  height: 400px !important;
}
